import Vue from 'vue'
import './styles/normalize.scss'
import './styles/rtl.scss'
import './styles/global.scss'
import AuthorizationService, {
  AuthQueryInterface
} from './services/authorization'
import App from './App.vue'
import store from './store'
import qs from 'qs'
import gameServerClient from './services/RemoteClient'
import CentrifugeSubscriber from './services/centrifuge'

const authParams = Object.entries(
  qs.parse(window.location.search, { ignoreQueryPrefix: true })
).reduce(
  (res, [param, val]) => ({
    ...res,
    ...(Object.prototype.hasOwnProperty.call(AuthQueryInterface, param)
      ? { [param]: val }
      : {})
  }),
  {}
)
const authorizationService = new AuthorizationService(
  gameServerClient,
  authParams
)
const centrifugeSubscriber = new CentrifugeSubscriber()
const chatSubscriber = new CentrifugeSubscriber()

Vue.mixin({
  methods: {
    $lc(content) {
      return this.$store.getters['localizations/localizeContent'](content)
    },

    $lui(key, params) {
      return this.$store.getters['localizations/localizeUI'](key, params)
    }
  }
})

new Vue({
  store,
  provide: {
    authorizationService,
    gameServerClient,
    centrifugeSubscriber,
    chatSubscriber
  },

  render: h => h(App)
}).$mount('#app')
