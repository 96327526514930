<template>
  <div class="game">
    <GameAudioChat v-if="audioChatVisible" />
    <div class="game__main-content">
      <LayoutComponent :transparent="certificateVisible" />
      <NavigationLogicComponent
        v-if="navVisible"
        ref="NavigationLogicComponent"
      />
    </div>
    <PopupGameCard ref="cardModal" />
    <PopupGameCardInHand ref="cardModalInHand" />

    <TrainerView class="game__info" />

    <StartNews v-if="startNewsVisible" class="game__info" />
    <GradeGameComponent
      v-if="pollVisible"
      class="grade-game"
      @change="handlePoll"
    />

    <StatisticsContainer v-if="statisticsVisible" />

    <TeamPlayerProfile
      v-if="currentModal === 'teamPlayerProfile'"
      class="game__info"
    />

    <TeamList v-if="currentModal === 'teamList'" class="game__info" />

    <AppLoader v-if="initializationStatus === 'loading'" />
    <EnterEmailPopup
      v-else-if="initializationStatus === 'get_credentials'"
      class="game__info"
      :http-code-of-error="connectErrorHttpCode"
      @submit="email => handleEmailSubmit(email)"
    />
    <ConnectionErrorScreen
      v-else-if="initializationStatus === 'terminal_state_connection_error'"
      class="game__info"
      :http-code-of-error="connectErrorHttpCode"
    />
    <ConnectionErrorScreen
      v-else-if="initializationStatus === 'no_content'"
      class="game__info"
      :http-code-of-error="connectErrorHttpCode"
      :show-refresh="false"
    />

    <FinishGame v-if="certificateVisible" class="game__info" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GradeGameComponent from './components/GradeGameComponent.vue'
import TeamPlayerProfile from './components/popups/TeamPlayerProfile.vue'
import TeamList from './components/popups/TeamList.vue'
import EnterEmailPopup from './components/EnterEmailPopup.vue'
import TrainerView from './components/TrainerView.vue'
import ConnectionErrorScreen from './components/ConnectionErrorScreen.vue'
import StartNews from './components/News/StartNews.vue'
import LayoutComponent from './components/LayoutComponent.vue'
import NavigationLogicComponent from './components/NavigationLogicComponent.vue'
import PopupGameCard from './components/popups/PopupGameCard.vue'
import PopupGameCardInHand from './components/popups/PopupGameInHand.vue'
import FinishGame from './components/FinishGame.vue'
import AppLoader from './components/AppLoader.vue'
import GameAudioChat from './components/GameAudioChat.vue'
import { getRemainingSeconds, syncWithServerTime } from './utils/datetime'
import localStorageService from './services/localStorage'
import * as navigationService from './services/navigation'
import * as localCacheService from './services/localCache'
import GameStates, { GameStatesMap } from './constants/gameStates'
import AUTH_TYPES from './constants/authTypes'
import BRANDING_TYPE from './constants/brandingType'
import SLIDES_VISIBILITY_TYPE from './constants/slidesVisibilityType'
import { SAVED_LOCALE_KEY } from './constants/locales'
import teamPlayerProfileContext from './constants/teamPlayerProfileContext'
import debug from './utils/debug'
import {
  attachGameListeners,
  GAME_NAMESPACE,
  GAME_STATE_CHANGE
} from './services/centrifuge/gameHandlers'
import { addSeconds } from 'date-fns'
import { arrayFindLastIndex } from './utils/base'
import StatisticsContainer from './components/StatisticsContainer.vue'

export default {
  name: 'App',
  components: {
    LayoutComponent,
    NavigationLogicComponent,
    PopupGameCard,
    PopupGameCardInHand,
    FinishGame,
    TeamPlayerProfile,
    TeamList,
    TrainerView,
    ConnectionErrorScreen,
    StartNews,
    GradeGameComponent,
    EnterEmailPopup,
    AppLoader,
    GameAudioChat,
    StatisticsContainer
  },

  inject: ['authorizationService', 'gameServerClient', 'centrifugeSubscriber'],
  data() {
    return {
      timerWorld: 0,
      initAfterSubscribedFromOffset: null,
      connectErrorHttpCode: null
    }
  },

  computed: {
    ...mapGetters({
      isCaptain: 'session/getIsCaptain',
      sessionTimerTime: 'session/timerTime',
      gameState: 'session/gameState',
      initializationStatus: 'session/initializationStatus',
      currentModal: 'session/currentModal',
      localeDirection: 'localizations/localeDirection',
      isInfoConsole: 'session/isInfoConsole',
      isInfoConsoleGame: 'session/isInfoConsoleGame',
      audioChat: 'session/hasChat'
    }),

    audioChatVisible() {
      return this.audioChat && !this.isInfoConsoleGame
    },

    certificateVisible() {
      return (
        !this.isInfoConsoleGame && this.gameState === GameStatesMap.Finished
      )
    },

    navVisible() {
      return !this.checkGameState([
        GameStatesMap.Finished,
        GameStatesMap.AfterStatistic,
        GameStatesMap.None,
        GameStatesMap.New
      ])
    },

    startNewsVisible() {
      return this.checkGameState([GameStatesMap.None, GameStatesMap.New])
    },

    statisticsVisible() {
      return this.checkGameState([GameStatesMap.AfterStatistic])
    },

    pollVisible() {
      return (
        !this.isInfoConsoleGame &&
        this.checkGameState([GameStatesMap.AfterPoll])
      )
    }
  },

  watch: {
    localeDirection(val) {
      document.body.setAttribute('dir', val)
    }
  },

  async mounted() {
    if (!this.authorizationService.canEstablishConnection()) {
      this.connectErrorHttpCode = 204
      this.$store.commit('session/SET_INITIALIZATION_STATUS', 'no_content')
      return
    }

    await this.initializeSession()
  },

  methods: {
    checkGameState(states) {
      return states?.includes(this.gameState) ?? false
    },

    hideAllPopups() {
      this.$refs.cardModal?.closeModalHard()
      this.$refs.cardModalInHand?.closeModal()
      this.$store.commit('session/HIDE_MODAL')
    },

    async handlePoll(x, y) {
      await this.gameServerClient.sendVote(x, y)
    },

    rebuildDiagram() {
      if (this.$refs.NavigationLogicComponent) {
        this.$refs.NavigationLogicComponent.rebuildDiagram()
      }
    },

    setTimeWork(newTime) {
      let worldMilliseconds = Date.now() + newTime * 1000
      this.timerWorld = worldMilliseconds
      if (this.sessionTimerTime <= 0) {
        this.$store.commit(
          'session/UPDATE_SESSION_TIMER',
          Math.floor((this.timerWorld - Date.now()) / 1000)
        )
        this.timerWork()
      } else {
        this.$store.commit(
          'session/UPDATE_SESSION_TIMER',
          Math.floor((this.timerWorld - Date.now()) / 1000)
        )
      }
    },

    timerWork() {
      if (this.timerWorld - Date.now() > 0) {
        let updateTime = this.timerWorld - Date.now()
        this.$store.commit(
          'session/UPDATE_SESSION_TIMER',
          Math.floor(updateTime / 1000)
        )

        if (updateTime > 0) {
          setTimeout(() => {
            this.timerWork()
          }, 1000)
        }
      } else {
        this.$store.commit('session/UPDATE_SESSION_TIMER', 0)
      }
    },

    async handleEmailSubmit(email) {
      await this.initializeSession({ email })
    },

    async initializeSession(credentials = null) {
      this.connectErrorHttpCode = null
      let worldData = await this.authorizationService.attemptReconnect()

      if (
        !worldData &&
        this.authorizationService.getAuthType() === AUTH_TYPES.BY_EMAIL &&
        !credentials
      ) {
        this.$store.commit(
          'session/SET_INITIALIZATION_STATUS',
          'get_credentials'
        )
        return
      }

      if (!worldData) {
        try {
          worldData = await this.authorizationService.connect(credentials)
        } catch (error) {
          this.connectErrorHttpCode = error.response?.status || -1

          if (
            !(
              credentials &&
              (this.connectErrorHttpCode === 400 ||
                this.connectErrorHttpCode === 401)
            )
          ) {
            this.$store.commit(
              'session/SET_INITIALIZATION_STATUS',
              'terminal_state_connection_error'
            )
          }

          return
        }
      }

      if (!worldData) {
        return
      }

      this.initializeSessionWorldData(worldData)
      this.initializePlannedGameCountdown(worldData.game.planned_at)
      this.initializeTeamProfileModal()
      this.initializeNavigation()
      this.initializeBranding(worldData.game.branding)
      this.initializeSlidesVisibility(worldData.game.slides_visibility)
      this.$store.commit('session/SET_INITIALIZATION_STATUS', 'ready')
      this.centrifugeSubscriber.on(
        `publish:${GAME_NAMESPACE}:${GAME_STATE_CHANGE}`,
        params => {
          this.hideAllPopups()
          this.initializeDefaultPage()

          const { step_time_limit, step_started_at } = params[0] || {}
          const dataOfStats = params[2] || null

          if (step_time_limit && step_time_limit > 0) {
            const datetimeBefore = addSeconds(
              new Date(step_started_at),
              step_time_limit
            )
            const seconds = getRemainingSeconds(datetimeBefore)

            if (seconds && seconds >= 0) {
              this.setTimeWork(seconds)
            }
          } else {
            this.setTimeWork(0)
          }

          if (dataOfStats && dataOfStats.length > 0) {
            setTimeout(() => {
              this.rebuildDiagram()
            }, 100)
          }
        }
      )
      attachGameListeners(
        this.centrifugeSubscriber,
        this.$store,
        this.isInfoConsoleGame
      )
      this.centrifugeSubscriber
        .on(`subscribe:${GAME_NAMESPACE}`, channel =>
          this.centrifugeSubscriber.republishFromHistory(
            channel,
            publications =>
              arrayFindLastIndex(
                publications,
                p => p.data.method === GAME_STATE_CHANGE
              )
          )
        )
        .connect(this.gameServerClient.getToken())
    },

    initializeSessionWorldData({ game, player, team, date }) {
      this.initializeTimeDelta(new Date(date))
      this.initializeScenarioData({ scenarios: game.scenarios })
      this.initializeGameData({ game })
      this.initializeLocaleData({ game })
      this.initializeTeamData({ team })
      this.initializePlayerData({ player })
      this.initializeDefaultPage()
    },

    initializeDefaultPage() {
      if (this.isInfoConsoleGame) {
        this.$store.commit('session/SET_CURRENT_SUB_PAGE', 'stats')
      }
    },

    initializeTimeDelta(serverTime) {
      const localTime = new Date()
      debug.app.info(
        'serverTime',
        serverTime.toISOString(),
        serverTime.valueOf()
      )
      debug.app.info('localTime', localTime.toISOString(), localTime.valueOf())

      syncWithServerTime(serverTime)
    },

    initializeGameData({ game }) {
      this.$store.commit('session/SET_GAME_ID', game.id)
      this.$store.commit('session/SET_GAME_STATE', GameStates[game.state])
      this.$store.commit('session/SET_SHARING_SOC_APPS', game.sharing || [])
      this.$store.commit('session/UPDATE_SECTION_DATA', { step: game.step })
      this.$store.commit('session/SET_CHAT_STATE', game.has_chat || false)
      this.$store.commit(
        'session/SET_CURRENCY_DISPLAY_MODE',
        game.currency_icon_display
      )
    },

    initializeLocaleData({ game: { locales } }) {
      this.$store.dispatch('localizations/setAvailableLocales', locales)

      const savedLocale = localStorageService.getItem(SAVED_LOCALE_KEY)
      if (savedLocale && locales.includes(savedLocale)) {
        this.$store.dispatch('localizations/setLocale', savedLocale)
      } else {
        this.$store.dispatch('localizations/setLocale', locales[0])
      }
    },

    initializeTeamData({ team }) {
      const { name, num, infrastructure, players } = team

      const captain = Object.values(players).find(player => player.is_captain)
      if (captain) {
        this.$store.commit('session/UPDATE_CAPTAIN_ID', captain.id)
      }

      this.$store.commit('session/UPDATE_SECTION_DATA', {
        teamName: name + ''
      })
      this.$store.commit('session/UPDATE_SECTION_DATA', { id: num })
      this.$store.commit('session/SET_TEAM', team)
      localCacheService.initialize(team.id)

      if (infrastructure) {
        this.$store.commit(
          'session/SET_GAMEBOARD_MARKINGS_STATUSES',
          infrastructure
        )
      }
    },

    initializePlayerData({ player }) {
      const {
        is_captain: isCaptain,
        id: playerId,
        nickname,
        is_info_console: isInfoConsole
      } = player

      this.$store.commit('session/SET_SESSION_PLAYER_DATA', {
        isCaptain,
        playerId,
        nickname,
        isInfoConsole
      })
    },

    initializeScenarioData({ scenarios }) {
      this.$store.commit('session/UPDATE_BASE_URL', scenarios.gameAssetsBaseUrl)
      this.$store.commit('session/SET_CURRENCY', scenarios.currency)
      this.$store.commit('session/UPDATE_SESSION_NAME', scenarios.industryTitle)
      this.$store.commit('session/SET_GAMEBOARD_DATA', scenarios.gameboard)

      this.$store.dispatch('cards/setCardData', {
        key: 'cardsLibrary',
        data: scenarios.cards
      })

      this.$store.commit('session/UPDATE_SESSION_MESSAGES_LIST', {
        news: scenarios.messages,
        bonus: scenarios.bonusMessages
      })
    },

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    initializePlannedGameCountdown(plannedAt) {
      if (this.gameState === GameStatesMap.New && plannedAt) {
        const seconds = getRemainingSeconds(plannedAt)

        if (seconds && seconds >= 0) {
          this.setTimeWork(seconds)
        }
      }
    },

    initializeTeamProfileModal() {
      if (this.checkGameState([GameStatesMap.New]) && !this.isInfoConsole) {
        this.$store.commit('session/SET_MODAL', {
          name: 'teamPlayerProfile',
          settings: { context: teamPlayerProfileContext.INITIAL }
        })
      }
    },

    initializeNavigation() {
      if (this.checkGameState([GameStatesMap.New, GameStatesMap.GameIntro])) {
        this.$store.commit('session/SET_CURRENT_SUB_PAGE', 'main')
      } else {
        const cachedNavigationValue = navigationService.get()
        if (
          cachedNavigationValue &&
          cachedNavigationValue !== 'null' &&
          cachedNavigationValue !== 'undefined'
        ) {
          this.$store.commit(
            'session/SET_CURRENT_SUB_PAGE',
            navigationService.get()
          )
        }
      }
    },

    initializeBranding(branding) {
      const type = BRANDING_TYPE[branding.type]
      const company_logo = branding.company_logo
      const company_title = branding.company_title

      this.$store.commit('session/SET_BRANDING', {
        type,
        company_logo,
        company_title
      })
    },

    initializeSlidesVisibility(state) {
      const type = SLIDES_VISIBILITY_TYPE[state]

      this.$store.commit('session/SET_SLIDES_VISIBILITY', type)
    }
  }
}
</script>

<style lang="scss">
.game {
  &__main-content {
    overflow: hidden;
  }

  &__info {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 250;
  }
}

.result-table {
  width: 449px;
  min-height: 442px;
}

.start-news {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.additional-component {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.grade-game {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100svh;
  z-index: 100;
}
</style>
