<template>
  <div class="quick-jump desc-quick-jump">
    <button
      class="btn"
      :class="{ 'btn--disabled': getNewNews.length === 0 }"
      @click="onClickQuickJump"
    >
      <div class="btn__inner-border">
        <span>
          <img
            class="btn__nav-icon"
            src="../assets/news/QJ-nav-icon.png"
            alt=""
          />
        </span>
        <span class="btn__text">{{ $lui('news.quick-jump') }}</span>
        <span class="btn__arrow">
          <img
            width="11px"
            height="5.5px"
            src="../assets/news/arrow-down.png"
            alt=""
          />
        </span>
      </div>
    </button>
    <DescPopUp
      class="dropdown"
      v-if="hiddenStepsTable"
      @block-select="handleSelectBlock"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DescPopUp from './DescPopUp.vue'

export default {
  name: 'QuickJump',
  components: {
    DescPopUp
  },

  data() {
    return {
      hiddenStepsTable: false,
      roundBtn: false
    }
  },

  computed: mapGetters({
    getNewNews: 'session/getNewNews'
  }),

  methods: {
    handleSelectBlock(id) {
      this.hiddenStepsTable = false
      this.$emit('block-select', id)
    },

    onClickQuickJump() {
      this.hiddenStepsTable = !this.hiddenStepsTable
    },

    getNews() {
      return this.getNewNews
    }
  }
}
</script>

<style lang="scss">
.dropdown {
  position: absolute;
  left: -32px;
  top: 56px;
  width: fit-content;
}

.quick-jump {
  position: relative;

  &__btn {
    display: block;
    padding: 0;
    margin-bottom: 9px;
    width: 171px;
    height: 50px;
    background-image: url(../assets/quickJumpBtnBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 18px !important;
    line-height: 21px !important;
    color: #000000;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
  }
  &__btn-content {
    display: flex;
    justify-content: center;
  }
}

.desc-quick-jump {
  @media screen and (max-width: 1551px) {
    width: 14.648vw;
  }
}

@keyframes visible {
  0% {
    transform: translateY(30%);
  }
  100% {
    transform: translateY(0);
  }
}

.arrow-down {
  margin-left: 8px;
}

.btn {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: 2px solid #000000;
  background: #e2b38a;
  border-radius: 50px;
  cursor: pointer;

  &--disabled {
    pointer-events: none;
    filter: grayscale(100%);
    opacity: 0.3;
  }

  @media screen and (min-width: 1024px) and (max-width: 1551px) {
    width: 14.648vw;
    height: 3.809vw;
  }

  @media screen and (max-width: 1024px) {
    border: 1px solid #000000;
    box-shadow: inset 5px 6px 0px #e9c6a7, inset -5px -5px 0px #c1946b;
  }

  &__text {
    margin-right: 0.644vw;
    white-space: nowrap;
  }

  &__nav-icon {
    width: 1.031vw;
    height: 1.418vw;
    margin-right: 0.644vw;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      width: 1.27vw;
      height: 1.758vw;
    }
  }

  &__inner-border {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 1.16vw;
    line-height: 1.353vw;
    border: 1px solid #000000;
    border-radius: 50px;
    box-shadow: inset 5px 6px 0 #e9c6a7, inset -5px -5px 0px #c1946b;
    padding: 10px;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      width: 13.867vw;
      height: 3.223vw;
      font-size: 1.406vw;
      line-height: 1.66vw;
    }

    @media screen and (max-width: 1024px) {
      border: none;
      box-shadow: none;
    }
  }
}
</style>
