import Vue from 'vue'
import Vuex from 'vuex'

import cards from './modules/cards.js'
import session from './modules/session'
import localizations from './modules/localizations'
import audioChat from './modules/audioChat'
import toast from './modules/toast'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    cards,
    session,
    localizations,
    audioChat,
    toast
  }
})
