import debug from '@/utils/debug'

export default {
  namespaced: true,

  state: {
    toast: {
      title: '',
      text: ''
    },
    visible: false,
    timeout: 7000
  },

  getters: {
    data(state) {
      return state.toast
    },
    visible(state) {
      return state.visible
    }
  },

  actions: {
    async showError({ dispatch }, { text = '' }) {
      dispatch('showToast', { title: 'toast.error', text })
    },
    async showToast({ commit, state }, { title = '', text = '' }) {
      commit('SET_TOAST_STATE', { title, text })
      commit('SET_TOAST_VISIBLE', true)

      setTimeout(() => {
        commit('SET_TOAST_VISIBLE', false)
      }, state.timeout)
    }
  },

  mutations: {
    SET_TOAST_STATE(state, data) {
      debug.info('cards/SET_TOAST_STATE > ', data)
      state.toast = data
    },
    SET_TOAST_VISIBLE(state, data) {
      debug.info('cards/SET_TOAST_VISIBLE > ', data)
      state.visible = data
    }
  }
}
