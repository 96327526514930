<template>
  <div class="start-game" :class="{ 'start-game__changeBG': false }">
    <div class="start-game__content">
      <div
        class="start-game__content-container"
        :class="{ isCaptain, isInitial }"
      >
        <button
          v-if="!isInitial"
          class="start-game__close-btn"
          @click="closePopup"
        >
          <img src="../../assets/close-btn-start-game.svg" alt="" />
        </button>

        <p v-if="isCaptain" class="start-game__greetings">
          {{ $lui(titleTextKey) }}
        </p>
        <p v-else class="start-game__greetings">
          {{ $lui(titleTextKey, { teamName: localTeamName }) }}
        </p>

        <div
          class="start-game__buttons"
          :class="{ isCaptain: isCaptain && !isInitial, isInitial: isInitial }"
        >
          <div>
            <div
              v-if="isInitial"
              :class="{
                'start-game__edit-mode-switch_checked': skipTeamProfileUpdate
              }"
              @click="skipTeamProfileUpdate = !skipTeamProfileUpdate"
              class="start-game__edit-mode-switch"
            >
              {{ $lui('team-profile-spectator.checkbox') }}

              <div class="start-game__edit-mode-switch-icon"></div>
            </div>

            <div
              v-if="isCaptain && !skipTeamProfileUpdate"
              :class="{ 'start-game__input-box_error': !!errors.teamName }"
              class="start-game__input-box"
            >
              <label for="teamName">{{ $lui('team-label.team') }}</label>
              <input
                id="teamName"
                class="start-game__input"
                maxlength="16"
                type="text"
                v-model="localTeamName"
                :disabled="!canEditTeamName"
                @keypress.enter="submitProfileData"
                @keyup.esc="closePopup"
              />
              <img
                class="start-game__input-bg"
                :class="{ 'start-game__input-bg--disabled': !canEditTeamName }"
                src="../../assets/input-start-game.png"
                alt=""
              />
              <span v-if="errors.teamName" class="start-game__error">
                {{ errors.teamName }}
              </span>
            </div>
            <div
              v-if="!skipTeamProfileUpdate"
              :class="{ 'start-game__input-box_error': !!errors.playerName }"
              class="start-game__input-box"
            >
              <label for="nickname">{{ $lui('team-label.nickname') }}</label>
              <input
                id="nickname"
                v-model="currentNickname"
                class="start-game__input"
                maxlength="20"
                type="text"
                :disabled="!canEditPlayerName"
                @keypress.enter="submitProfileData"
                @keyup.esc="closePopup"
              />
              <img
                class="start-game__input-bg"
                :class="{
                  'start-game__input-bg--disabled': !canEditPlayerName
                }"
                src="../../assets/input-start-game.png"
                alt=""
              />
              <span v-if="errors.playerName" class="start-game__error">
                {{ errors.playerName }}
              </span>
            </div>

            <div class="start-game__bottom-section">
              <div class="start-game__select-language">
                <SelectLang />
              </div>
              <button
                v-if="canEditTeamName || canEditPlayerName"
                class="start-game__start-btn"
                @click="submitProfileData"
              >
                {{ $lui(submitTextKey) }}
              </button>
            </div>

            <div class="start-game__legal-notices">
              <div
                class="start-game__legal-notices-button"
                @click="legalNoticesPopupOpened = true"
              >
                {{ $lui('legal-notices') }}
              </div>
            </div>

            <AppBranding
              :kaspersky-logo-width="95"
              class="start-game__branding"
            />
          </div>
        </div>
      </div>
    </div>

    <LegalNotices :opened.sync="legalNoticesPopupOpened" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SelectLang from '../SelectLang.vue'
import scaleTable from '../../mixins/scaleTable'
import teamPlayerProfileContext from '../../constants/teamPlayerProfileContext'
import LegalNotices from '../LegalNotices.vue'
import AppBranding from '../AppBranding.vue'

export default {
  name: 'TeamPlayerProfile',

  components: {
    SelectLang,
    LegalNotices,
    AppBranding
  },

  mixins: [scaleTable],
  data() {
    return {
      localTeamName: '',
      currentNickname: '',
      errors: {},
      skipTeamProfileUpdate: false,
      legalNoticesPopupOpened: false
    }
  },

  computed: {
    ...mapGetters({
      isCaptain: 'session/getIsCaptain',
      teamName: 'session/teamName',
      currentPlayerNickname: 'session/currentPlayerNickname',
      gameState: 'session/gameState',
      selectedLang: 'lang/selectedLang', /// fixme use me
      canEditTeamName: 'session/canEditTeamName',
      canEditPlayerName: 'session/canEditPlayerName',
      modalSettings: 'session/modalSettings'
    }),

    titleTextKey() {
      if (this.isCaptain) {
        return `team-profile.title${
          this.isInitial
            ? '--welcome'
            : (this.canEditTeamName && '--editable') || ''
        }`
      } else {
        return `team-profile-spectator.title${
          this.isInitial
            ? '--welcome'
            : (this.canEditTeamName && '--editable') || ''
        }`
      }
    },

    submitTextKey() {
      return `team-profile${this.isCaptain ? '' : '-spectator'}.submit${
        this.isInitial ? '--first' : ''
      }`
    },

    isInitial() {
      return this.modalSettings?.context === teamPlayerProfileContext.INITIAL
    }
  },

  watch: {
    teamName() {
      this.localTeamName = this.teamName
    }
  },

  methods: {
    ...mapActions('session', ['changeTeamData']),
    setBlockScale() {
      let resultHeight = (window.innerHeight - 70 - 67) * 0.97
      let resultScaleH = resultHeight / 750

      let resultWidth = window.innerWidth * 0.85
      let resultScaleW = resultWidth / 550

      this.scaleTable = Math.min(resultScaleH, resultScaleW)
    },

    closePopup() {
      this.$store.commit('session/HIDE_MODAL')
    },

    async submitProfileData() {
      if (this.skipTeamProfileUpdate) {
        this.closePopup()
        return
      }

      this.errors = await this.changeTeamData({
        playerName: this.currentNickname,
        teamName: this.localTeamName
      })

      if (!Object.keys(this.errors).length) {
        this.closePopup()
      }
    }
  },

  mounted() {
    this.localTeamName = this.teamName
    this.currentNickname = this.currentPlayerNickname
  }
}
</script>

<style lang="scss" scoped>
.start-game {
  width: 100%;
  height: 100svh;
  background-color: rgba(25, 35, 44, 0.7);

  &__branding {
    margin-top: 5px;
  }

  &__legal-notices {
    display: flex;
    justify-content: center;
  }

  &__legal-notices-button {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 270px;

    &.isCaptain {
      height: 380px;
    }

    &.isInitial {
      display: block;
    }
  }

  &__close-btn {
    position: absolute;
    top: 4%;
    right: 165px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  &__content {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 560px;
    height: 100%;
  }

  &__content-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 31px;
    width: 823px;
    height: 410px;
    background-image: url(../../assets/start-game-info-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 10;

    &.isCaptain {
      width: 723px;
      height: 461px;
      background-image: url(../../assets/start-game-info-bg-big.png);

      .start-game__close-btn {
        top: 0;
        right: 130px;
      }
    }

    &.isInitial {
      &:not(.isCaptain) {
        background-image: url(../../assets/start-game-info-bg-big.png);
        width: 716px;
        height: 455px;
      }

      width: 800px;
      height: 508px;
    }
  }

  &__greetings {
    width: 280px;
    margin: 0 auto 29px;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: #542720;
  }

  &__input {
    position: absolute;
    width: 330px;
    height: 47px;
    padding: 6px 9px;
    z-index: 9;
    border: none;
    background: transparent;
    outline: none;
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 25px;
    color: #000000;
    -webkit-background-clip: text;

    &-box {
      position: relative;
      width: 333px;
      height: 51px;
      margin: 0 auto 25px;

      label {
        position: absolute;
        left: 0;
        top: -20px;
      }

      &_error {
        margin-bottom: 40px;

        .start-game__error {
          position: absolute;
          top: 50px;
          left: 0;
          color: red;
          font-size: 12px;
        }
      }
    }

    &-error {
      color: red;
      position: absolute;
      bottom: -20px;
    }

    &-bg {
      position: absolute;
      width: 329px;
      height: 51px;

      &--disabled {
        opacity: 0.5;
      }
    }
  }

  &__bottom-section {
    display: flex;
    justify-content: space-around;
    width: 326px;
    margin: 0 auto;

    position: relative;
    z-index: 1;
  }

  &__select-language {
    width: 105px;
    height: 45px;
  }

  &__start-btn {
    width: 179px;
    height: 54px;
    padding: 0;
    padding-bottom: 5px;
    border: none;
    background: transparent;
    background-image: url(../../assets/start-game-btn.png);
    background-repeat: no-repeat;
    background-size: cover;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    opacity: 0.8;
    color: #ffffff;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  &__enter-btn {
    position: absolute;
    right: 105px;
    bottom: -11%;
    padding: 17px 12px 9px;
    width: 118px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #ffffff;
    background: #000;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    z-index: 0;
  }

  &__edit-mode-switch {
    background: #d3a075;
    padding: 10px 10px;
    width: 324px;
    margin: 0 auto;
    transform: translateX(-4px);
    border-radius: 5px;
    margin-bottom: 30px;
    cursor: pointer;

    &_checked {
      .start-game__edit-mode-switch-icon {
        background-image: url(../../assets/checkbox-disabled.svg);
      }
    }
  }

  &__edit-mode-switch-icon {
    background-image: url(../../assets/checkbox-enabled.svg);
    background-repeat: no-repeat;
    position: absolute;
    right: 16px;
    top: 10px;
    width: 41px;
    height: 25px;
  }
}

.start-game__changeBG {
  background-color: #19232c;
}
</style>
