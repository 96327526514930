<template>
  <div class="in-hand" :style="`--gap: ${cardsGapValue}px;`">
    <div
      v-for="(item, i) in getCardFromHand"
      :key="i"
      class="in-hand__card"
      :style="{ zIndex: i + 1 }"
    >
      <Card :from="'table'" :item="item" />
    </div>
    <div class="open-modal-area pointer" @click="openModal"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Card from './GameCard.vue'
import cardsGap from '@/mixins/cardsGap'

export default {
  components: {
    Card
  },

  mixins: [cardsGap],

  props: {
    containerWidth: {
      type: Number,
      default: 300
    }
  },

  watch: {
    containerWidth() {
      this.cardsContainerSize = this.containerWidth
    }
  },

  methods: {
    openModal() {
      this.$root.cardModalInHand.show()
    },

    handleResize() {
      this.cardsContainerSize = this.containerWidth
    }
  },

  computed: {
    ...mapGetters({
      getCardFromHand: 'cards/UICardsInHand'
    }),

    cardsInRowCount() {
      return this.getCardFromHand.length
    }
  }
}
</script>

<style lang="scss" scoped>
.open-modal-area {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 30;

  @include rtl {
    left: var(--gap);
  }
}

.pointer {
  cursor: pointer;
}

.in-hand {
  display: flex;
  transition: all 0.05s ease-in !important;

  &:hover {
    transform: translateY(-15px);
  }

  &__card {
    position: relative;
    animation: bounce-in 1s;

    width: 85px;
    height: 121px;

    &:not(:first-child) {
      margin-left: var(--gap);
    }
  }
}

@keyframes bounce-in {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  25% {
    transform: translateY(-10px);
    opacity: 0;
  }
  50% {
    transform: translateY(-10px);
    opacity: 0;
  }
  50% {
    transform: translateY(-10px);
    opacity: 0;
  }
  60% {
    transform: translateY(-8px);
    opacity: 0.3;
  }
  70% {
    transform: translateY(-6px);
    opacity: 0.5;
  }
  80% {
    transform: translateY(-4px);
    opacity: 0.6;
  }
  90% {
    transform: translateY(-2px);
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
