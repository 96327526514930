<template>
  <div
    v-if="visible"
    dir="ltr"
    class="toast__container"
    :class="{ toast__container_showing: visible }"
  >
    <p class="toast__title" v-html="title"></p>
    <p class="toast__text" v-html="toastData.text"></p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('toast', {
      visible: 'visible',
      toastData: 'data'
    }),

    title() {
      return this.$lui(this.toastData.title) || this.toastData.title
    }
  }
}
</script>

<style lang="scss">
.toast {
  cursor: pointer;

  &__container {
    width: fit-content;
    height: 45px;
    background: #f1c299;
    border: solid 1px #000;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 6px;
    padding: 8px;
    box-shadow: inset 5px 6px 0px #e9c6a7, inset -5px -5px 0px #c1946b,
      rgba(0, 0, 0, 0.3) 0px 2px 0px 1px;
    transition: opacity 0.5s ease-in;
    opacity: 0;

    &_showing {
      opacity: 1;
      transition: opacity 0.5s ease-in;
    }
  }

  &__title {
    line-height: 13px;
    font-size: 14px;
    font-weight: bold;
    color: #1c1c1c;
    margin-bottom: 4px;
  }

  &__text {
    font-size: 11px;
    line-height: 10px;
    color: #323232;
  }
}
</style>
