<template>
  <div class="table inner-container">
    <div class="table__container">
      <div class="table__light-cards">
        <CardsPlayedLight />
      </div>
      <!-- Bottom side cards -->
      <!--      <div class="table__green-cards">-->
      <!--        <CardsPlayedGreen />-->
      <!--      </div>-->
      <div class="table__game">
        <Industrial />
      </div>
    </div>
  </div>
</template>

<script>
import CardsPlayedLight from './CardsContainerPlayedLight.vue'
import Industrial from '../IndustrialContent.vue'
import scaleTable from '../../mixins/scaleTable'

export default {
  mixins: [scaleTable],
  components: {
    CardsPlayedLight,
    Industrial
  },

  methods: {
    setBlockScale() {
      let heightFooterAndHeader = 70 + 67
      let indentY = 0.94
      let neededHeight = 730
      let resultHeigth = (window.innerHeight - heightFooterAndHeader) * indentY
      let resultScaleH = resultHeigth / neededHeight

      let indentX = 0.6
      let neededWidth = 970

      let resultWidth = window.innerWidth * indentX
      let resultScaleW = resultWidth / neededWidth

      this.scaleTable = Math.min(resultScaleH, resultScaleW)
    }
  }
}
</script>

<style lang="scss" scoped>
$b: '.table';

#{$b} {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 14;

  &__container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(6, 1fr);
    gap: 15px;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .table__light-cards {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 7;
      width: 100%;
      height: 100%;
    }

    .table__green-cards {
      grid-column-start: 2;
      grid-column-end: 6;
      grid-row-start: 6;
      grid-row-end: 7;
      width: 100%;
      height: 121px;
      padding-left: 75px;
      padding-right: 20px;
    }

    .table__game {
      grid-column-start: 2;
      grid-column-end: 6;
      grid-row-start: 1;
      grid-row-end: 7;
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
    }
  }
}
</style>
