<template>
  <div ref="cardsContainer" class="light-cards">
    <div
      v-for="(item, i) in cards"
      v-bind:key="i"
      class="light-cards__container"
      :style="`margin-top:${
        i % 2 === 1 ? (i / 2 - 0.5) * cardsGapValue : (i / 2) * cardsGapValue
      }px`"
    >
      <Card
        :from="'table'"
        :item="item"
        :next-card-in-table-is-empty="
          !item.cardEmpty && cards[i + 2] && cards[i + 2].cardEmpty
        "
      />
    </div>
  </div>
</template>

<script>
import Card from './GameCard.vue'
import { mapGetters } from 'vuex'
import cardsGap from '@/mixins/cardsGap'

export default {
  components: {
    Card
  },

  mixins: [cardsGap],

  data() {
    return {
      cardsOrientation: 'vertical'
    }
  },

  computed: {
    ...mapGetters({
      getPlayedCards: 'cards/UICardsUsed'
    }),

    cards() {
      return this.getPlayedCards[0]
    },

    cardsInRowCount() {
      return this.getPlayedCards[0].length / 2
    }
  }
}
</script>

<style lang="scss" scoped>
$b: '.light-cards';

#{$b} {
  display: grid;
  grid-template-columns: repeat(2, 85px);
  grid-template-rows: repeat(12, 121px);
  column-gap: 25px;
  height: 100%;

  &__container {
    margin: 0;
    width: 85px;
    height: 121px;
  }
}
</style>
