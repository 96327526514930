import resize from '@/mixins/resize'

const DEFAULT_GAP = {
  horizontal: -5,
  vertical: -63
}
const CARD_SIZE = {
  horizontal: 85,
  vertical: 121
}
export default {
  mixins: [resize],

  data() {
    return {
      cardsOrientation: 'horizontal', // 'vertical'
      cardsContainerSize: 0,
      cardsGapValue: DEFAULT_GAP.horizontal
    }
  },

  watch: {
    cardsContainerSize() {
      this.setCardsGap()
    },

    cardsInRowCount() {
      this.setCardsGap()
    }
  },

  methods: {
    handleResize() {
      this.cardsContainerSize =
        this.cardsOrientation === 'horizontal'
          ? this.$refs.cardsContainer.clientWidth
          : this.$refs.cardsContainer.clientHeight
    },

    setCardsGap() {
      this.cardsGapValue = this.getCardsGap(
        this.cardsContainerSize,
        this.cardsInRowCount,
        CARD_SIZE[this.cardsOrientation],
        DEFAULT_GAP[this.cardsOrientation]
      )
    },

    getCardsGap(containerSize, itemsCount, cardSize, defaultGap) {
      const totalItemsSize = itemsCount * cardSize
      const remainingSize = containerSize - totalItemsSize
      const gap = remainingSize / (itemsCount - 1)

      return Math.min(gap, defaultGap)
    }
  }
}
