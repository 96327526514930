export default {
  methods: {
    onResizeCallback() {
      if (this.handleResize) {
        this.handleResize()
      }
    }
  },

  mounted() {
    this.onResizeCallback()
    window.addEventListener('resize', this.onResizeCallback)
  },

  destroyed() {
    window.removeEventListener('resize', this.onResizeCallback)
  }
}
