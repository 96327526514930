<template>
  <div
    class="game-card__wrapper"
    :class="{ 'game-card__wrapper--selected': item.hide === true }"
  >
    <div
      v-if="item"
      class="game-card"
      :class="{
        locked: item.block,
        'game-card-shadow': item.id,
        clickable: item.id,
        'card-theme': item.type != 'one_time',
        'info-card': item.type === 'info',
        'card-full': inZoom === true || inZoomInHand === true,
        'card-full__in-hand': inZoomInHand === true,
        'from-table': from === 'table',
        'from-table__next-empty': nextCardInTableIsEmpty,
        'close-green': item.cardEmpty && item.cardColor == 'green',
        'close-lightGreen': item.cardEmpty && item.cardColor == 'yellow',
        'empty-card': false,
        'decisions-card': newsCard
      }"
      @click="openCardFull(item)"
    >
      <div v-if="item.new" class="new-mark">
        <img src="../../assets/cards/mark-new.svg" />
      </div>
      <div class="game-card__info">
        <p v-if="item.cardNumber" class="game-card__info-num">
          <NumCard :item="item" :class="{ 'decisions-card__num': newsCard }" />
        </p>

        <div class="game-card__info-inner-wrapper">
          <div>
            <h2 v-if="item.title" class="game-card__info-header">
              {{ localizedTitle }}
            </h2>
            <p
              v-show="cardOpened"
              :style="{ fontSize: cardDescriptionTextSize }"
              class="game-card__info-desc"
            >
              {{ localizedDescription }}
            </p>
          </div>

          <div v-if="imageVisible" class="game-card__info-image">
            <img
              :src="baseUrl + '/' + item.image"
              draggable="false"
              dir="ltr"
              @dragstart="returnFalse"
            />
          </div>
        </div>

        <div v-if="item.cost || item.timeUnit" class="game-card__info-cost">
          <p class="info-cost">
            {{ item.cost }}
          </p>
          <p class="info-timeCost">
            {{ item.timeUnit }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NumCard from './NumCard.vue'

const CARD_DESCRIPTION_LENGTH_THRESHOLD = 200
const CARD_TITLE_MAX_LENGTH_TO_FIT_IMAGE = 50
const CARD_DESCRIPTION_MAX_LENGTH_TO_FIT_IMAGE = 300

export default {
  components: {
    NumCard
  },

  props: {
    item: {},
    from: {},
    inZoom: {
      default: false
    },

    inZoomInHand: {
      default: false
    },

    newsCard: {
      type: Boolean,
      default: false
    },

    nextCardInTableIsEmpty: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      show: false
    }
  },

  computed: {
    ...mapGetters({
      baseUrl: 'session/getBaseUrl'
    }),

    cardOpened() {
      return this.inZoom || this.inZoomInHand
    },

    localizedTitle() {
      return this.$lc(this.item.title)
    },

    localizedDescription() {
      return this.$lc(this.item.description)
    },

    imageVisible() {
      const imageFittable =
        this.localizedTitle.length < CARD_TITLE_MAX_LENGTH_TO_FIT_IMAGE

      const inZoomImageFittable =
        this.localizedDescription.length <
        CARD_DESCRIPTION_MAX_LENGTH_TO_FIT_IMAGE

      return (
        this.item.image &&
        ((!this.cardOpened && imageFittable) ||
          (this.cardOpened && inZoomImageFittable))
      )
    },

    cardDescriptionTextSize() {
      return this.localizedDescription?.length >
        CARD_DESCRIPTION_LENGTH_THRESHOLD
        ? '12px'
        : '14px'
    }
  },

  methods: {
    openCardFull(item) {
      if (item.id && !this.inZoom && !this.inZoomInHand) {
        this.$root.cardModal.show(item)
      }
    },

    returnFalse() {
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.hide_card {
  display: none !important;
}

.new-mark {
  z-index: 500;
  position: absolute;
  left: 0;
  top: 0;
  width: 35px;
  height: 35px;

  img {
    width: 100%;
  }
}

$b: '.game-card';

#{$b} {
  box-sizing: border-box;
  width: 121px;
  height: 170px;
  text-align: center;
  background-image: url(../../assets/cards/greenCard.png);
  background-repeat: no-repeat;
  background-size: 119px 169px;
  position: relative;
  overflow: hidden;
  border: 1px solid black;

  & .game-card__info-num {
    position: absolute;
    top: 6px;
    left: 50%;
    transform: translateX(-60%);
  }

  .info-cost,
  .info-timeCost {
    color: #000;
  }

  &__used {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 10;
  }

  &__info {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 5;

    &-header {
      font-size: 9px;
      font-weight: bold;
      line-height: 14px;
      padding: 0 15px;
      text-align: center;
      width: 100%;
      text-transform: uppercase;
    }

    &-inner-wrapper {
      position: absolute;
      top: 17%;
      height: 65%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-image {
      overflow: hidden;
      min-height: 30px;
      max-height: 44px;
      flex-grow: 0;
      margin-top: 3px;
      margin-bottom: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        max-height: 100%;
        max-width: 75%;
        padding: 4px 4px 0px 4px;
        object-fit: scale-down;
        user-drag: none;
        -webkit-user-drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        pointer-events: none;

        &[dir='ltr'] {
          transform: scaleX(1);
        }
      }
    }

    &-cost {
      font-size: 8px;
      font-weight: 600;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 18px;
      height: 8px;

      .info-cost {
        bottom: 3px;
        transform: translateY(-8%);
        position: absolute;
        margin-left: 29px;
        height: inherit;
        width: 29px;
        text-align: center;
      }

      .info-timeCost {
        bottom: 3px;
        transform: translateY(-8%);
        position: absolute;
        margin-left: 69px;
        height: inherit;
        width: 18px;
        text-align: center;
      }
    }
  }

  &.card-full {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 302.5px;
    height: 425px;
    background-size: contain;

    .new-mark {
      height: 87.5px;
      width: 87.5px;
    }

    .game-card__info-inner-wrapper {
      padding: 0 24px;
    }

    .game-card__info-image {
      height: 70px;
      min-height: unset;
      max-height: unset;
      flex-shrink: 0;
      margin-bottom: 8px;
    }

    .game-card__info-cost {
      left: 50%;
      right: 0;
      bottom: 72px;
      height: 0;
      transform: translateX(-50%);
    }

    .info-cost {
      margin-left: 18px;
      font-size: 15px;
      bottom: 2px;
    }

    .info-timeCost {
      margin-left: 107px;
      font-size: 15px;
      bottom: 2px;
    }

    .num {
      width: auto;
      height: 32.5px;
    }

    #{$b}__info-header {
      padding-top: 0%;
      text-align: center;
      position: relative;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
    }

    #{$b}__info-desc {
      font-size: 14px;
      padding: 0 17px;
      font-weight: 400;
      margin-top: 10px;
    }
  }

  &.card-full__in-hand {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 302.5px;
    height: 425px;
    background-size: contain;

    .new-mark {
      height: 87.5px;
      width: 87.5px;
    }

    .game-card {
      width: 302.5px;
      height: 425px;
      background-size: contain;
    }

    .game-card__info-inner-wrapper {
      padding: 0 24px;
    }

    .game-card__info-cost {
      left: 50%;
      right: 0;
      bottom: 72px;
      height: 0;
      transform: translateX(-50%);
    }

    .info-cost {
      margin-left: 18px;
      font-size: 15px;
      bottom: 0;
    }

    .info-timeCost {
      margin-left: 107px;
      font-size: 15px;
      bottom: 0;
    }

    .num {
      width: auto;
      height: 32.5px;
    }

    &__in-hand {
      transform: translate(-50%, -50%) scale(2.5);
    }

    #{$b}__info-header {
      padding-top: 0%;
      text-align: center;
      position: relative;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
    }

    #{$b}__info-desc {
      padding: 0 17px;
      font-weight: 400;
    }
  }
}

.game-card__wrapper--selected {
  background: #4b647c;
  opacity: 0.15;

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
}

.disabled {
  filter: grayscale(100%);
  opacity: 0.3;
}

.locked {
  filter: grayscale(100%);
}

.clickable {
  cursor: pointer;
}

.from-table {
  transform: scale(0.71) translate(-20%, -20%);

  &__next-empty {
    z-index: 10;
  }
}

.card-theme {
  /// not good naming ideomatic
  background-image: url(../../assets/cards/yellowCard.png);
  background-size: 119px 169px;

  .info-cost,
  .info-timeCost {
    color: #000;
  }
}

.info-card {
  background-image: url(../../assets/cards/blueCard.png);
}

.close-green {
  background-image: url(../../assets/cards/card-dark.png) !important;

  #{$b}__info-image {
    display: none;
  }
}

.close-lightGreen {
  background-image: url(../../assets/cards/card-light.png) !important;

  #{$b}__info-image {
    display: none;
  }
}

.empty-card {
  background-image: url(../../assets/cards/card-ind.png) !important;
  background-size: 119px 170px;

  #{$b}__info {
    &-num {
      display: none;
    }

    &-header {
      top: 21%;
    }
  }
}

.decisions-card {
  width: 181.5px;
  height: 255px;
  box-sizing: border-box;
  text-align: center;
  background-image: url(../../assets/cards/greenCard.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 1551px) {
    width: 120px;
    height: 169px;
  }

  &__num {
    width: 45px;
    height: 20px;

    @media screen and (max-width: 1551px) {
      width: 29px;
      height: 13px;
    }
  }

  #{$b}__info {
    &-cost {
      font-size: 10px;

      @media screen and (max-width: 1551px) {
        font-size: 7px;
      }

      .info-cost {
        bottom: 16px;
        position: absolute;
        margin-left: 47px;
        height: inherit;
        width: 29px;
        text-align: center;

        @media screen and (max-width: 1551px) {
          bottom: 0.5px;
          margin-left: 29px;
        }
      }

      .info-timeCost {
        bottom: 16px;
        position: absolute;
        margin-left: 108px;
        height: inherit;
        width: 18px;
        text-align: center;

        @media screen and (max-width: 1551px) {
          bottom: 0.5px;
          margin-left: 68px;
        }
      }
    }

    &-image {
      top: 43%;

      img {
        width: 139px;
        height: 90px;
        object-fit: scale-down;

        @media screen and (max-width: 1551px) {
          width: 64px;
          height: 54px;
        }
      }
    }

    &-header {
      font-size: 10px;
      width: 100px;
      padding: 0;
      left: 22%;

      @media screen and (max-width: 1551px) {
        font-size: 8px;
        width: 87px;
        left: 14%;
      }
    }
  }

  & .info-cost,
  & .info-timeCost {
    color: #000000;
  }
}

.cards {
  #{$b} {
    transition: all 0.15s ease-in-out;
    will-change: transform;

    &:hover {
      transform: scale(1.1);

      .game-card__info-header {
        font-size: 9px;
      }
    }
  }
}
</style>
