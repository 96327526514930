var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-wrap",class:{ 'footer-wrap--transparent': _vm.transparent }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.gameState !== 'rewarding'),expression:"gameState !== 'rewarding'"}],class:{
      footer: _vm.allGameFooter,
      'finish-game-footer': !_vm.allGameFooter
    }},[_c('div',{staticClass:"footer__left-part"},[_c('img',{attrs:{"height":"70","src":require("../assets/footer/left-part.png"),"alt":""}}),(_vm.room && _vm.enabled)?_c('div',{staticClass:"footer__chat-btn",class:{
          'footer__chat-btn-disabled': !_vm.canPublish || _vm.muted || !_vm.connected
        },on:{"click":_vm.toggleMute}},[_c('div',{staticClass:"footer__chat-btn-icon"})]):_vm._e(),(_vm.room)?_c('button',{staticClass:"footer__audio-chat-icon footer__active-btn",on:{"click":_vm.toggleChatEnabled}},[(_vm.enabled)?_c('img',{attrs:{"src":require("../assets/footer/sound-on.svg")}}):_c('img',{attrs:{"src":require("../assets/footer/sound-off.svg")}})]):_vm._e(),_c('FooterInfoBlockComponent',{staticClass:"footer__info-block"})],1),_c('img',{staticClass:"footer__wires",attrs:{"height":"80","width":"58","src":require("../assets/footer/wires.png"),"alt":""}}),_c('div',{ref:"right-part",staticClass:"footer__right-part"},[_c('img',{staticClass:"footer__first",attrs:{"src":require("../assets/footer/left-long-part.png"),"alt":""}}),_vm._m(0),_c('div',{staticClass:"footer__last-part"},[_c('img',{staticClass:"footer__last",attrs:{"src":require("../assets/footer/right-long-part.png"),"alt":"","dir":"ltr"}}),_c('div',{staticClass:"footer__btn",class:_vm.getFooterButtonModificatorClasses('footer__btn'),on:{"click":_vm.chooseCards}},[_c('div',{staticClass:"footer__btn_border",class:_vm.getFooterButtonModificatorClasses('footer__btn_border')},[_vm._v(" "+_vm._s(_vm.choiceIsDone ? _vm.$lui('footer.revert') : _vm.$lui('footer.send'))+" ")])])])]),_c('CardsInHand',{staticClass:"footer__cards-in-hand",class:_vm.currentSubPage == 'cards' ? 'open' : '',attrs:{"container-width":_vm.footerCardsContainerWidth}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__middle"},[_c('img',{attrs:{"src":require("../assets/footer/middle-long-part.png"),"alt":""}})])
}]

export { render, staticRenderFns }