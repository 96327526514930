<template>
  <div
    class="cards__modal"
    :class="{ show: activeModal === true, 'from-hand': item.from === 'hand' }"
  >
    <div class="cards__modal_background" @click="closeModal"></div>
    <div
      v-show="inZoom === true"
      class="cards__modal-act"
      :style="getScaleStyle()"
      :aria-disabled="choiceInProgress"
    >
      <div ref="actBtn" class="cards__modal-act__btn" @click="closeModal"></div>
      <GameCard
        class="cards__modal-item"
        @click.native="getCardToHand"
        :item="item"
        :inZoom="inZoom"
      />
    </div>
  </div>
</template>

<script>
import GameCard from '../Cards/GameCard.vue'
import { mapGetters } from 'vuex'
import debug from './../../utils/debug'
import AppToast from '@/components/AppToast.vue'
import scaleTable from '../../mixins/scaleTable'

export default {
  name: 'PopupGameCard',
  components: {
    AppToast,
    GameCard
  },

  mixins: [scaleTable],

  props: {
    dataActiveCard: {}
  },

  data() {
    return {
      item: {},
      inZoom: false,
      activeModal: false,
      modalButton: true
    }
  },

  computed: mapGetters({
    isCaptain: 'session/getIsCaptain',
    gameState: 'session/gameState',
    choiceIsDone: 'cards/choiceIsDone',
    choiceInProgress: 'cards/choiceInProgress'
  }),

  methods: {
    show(item) {
      this.item = item
      this.inZoom = true
      this.activeModal = true
    },

    closeModalHard() {
      this.activeModal = false
    },

    closeModal: function (event) {
      if (this.choiceInProgress) {
        return
      }
      if (event.target === event.currentTarget) {
        this.activeModal = false
      }
      if (event === true) {
        this.activeModal = false
      }
    },

    getCardToHand: function () {
      debug.game.info('getCardToHand')
      if (this.choiceInProgress) {
        return
      }
      if (
        this.isCaptain &&
        this.gameState == 'phase_action' &&
        !this.choiceIsDone
      ) {
        if (
          this.item.availableToHand &&
          !this.item.block &&
          !this.item.hide &&
          !this.item.cardEmpty
        ) {
          this.$store
            .dispatch('cards/addCardToHand', this.item.id)
            .finally(() => this.closeModal(true))
        }
      } else {
        this.closeModal(true)
      }
    },

    setBlockScale() {
      let heightFooterAndHeader = 70 + 67
      let indentY = 0.99
      let neededHeight = 520

      let resultHeigth = (window.innerHeight - heightFooterAndHeader) * indentY
      let resultScaleH = resultHeigth / neededHeight

      let indentX = 0.9
      let neededWidth = 768

      let resultWidth = window.innerWidth * indentX
      let resultScaleW = resultWidth / neededWidth

      this.scaleTable = Math.min(resultScaleH, resultScaleW)
    }
  },

  mounted() {
    this.$root.cardModal = this
  }
}
</script>

<style lang="scss" scoped>
$b: '.cards';

#{$b} {
  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: none;

    &_background {
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.989233193277311) 0%,
        rgba(0, 0, 0, 0.1516981792717087) 100%
      );
      z-index: 180;
      position: fixed;
      width: 100%;
      height: 100%;
    }

    &.show {
      display: block;
    }

    &-btn {
      width: 209px;
      height: 67px;
      border: none;
      background-color: transparent;
      color: white;
      background-image: url(../../assets/header/info-block-bg-green.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      transform: translate(-50%, -50%);
      bottom: 12%;
      left: 50%;

      &:hover {
        transform: translate(-50%, -50%) scale(1.05);
      }
    }

    &-item {
      z-index: 215;
    }

    &-act {
      z-index: 300;
      position: absolute;
      width: 302.5px;
      height: 425px;
      top: 48%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
      transition: all 0.3s;

      &[aria-disabled='true'] {
        transform: translate(-50%, -50%) scale(1.03);

        #{$b}__modal-act__btn {
          display: none;
        }
      }

      &__btn {
        width: 55px;
        height: 55px;
        border-bottom-left-radius: 95%;
        background-color: rgba($color: #000000, $alpha: 0.5);
        position: absolute;
        top: 0;
        right: 0;
        z-index: 310;
        background-image: url(../../assets/cards/delete.svg);
        background-size: 22px;
        background-position: 23px 12px;
        background-repeat: no-repeat;
        cursor: pointer;
        transition: all 0.1s ease-in;

        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.7);
        }
      }
    }
  }
}

.from-hand {
  #{$b}__modal-act__btn {
    background-image: url(../../assets/cards/basket.svg);
  }
}
</style>
