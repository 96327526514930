<template>
  <div class="app-branding">
    <div
      v-if="branding.type === $options.BRANDING_TYPES.DEFAULT"
      class="app-branding__default"
    >
      <slot name="before-default-logo"></slot>

      <img
        src="../assets/kaspersky-logo_black.png"
        class="app-branding__image app-branding__kaspersky-logo"
        :style="kasperskyLogoStyles"
        alt="kaspersky logo"
        dir="ltr"
      />
    </div>

    <div
      v-else-if="branding.type === $options.BRANDING_TYPES.CO_BRANDING"
      class="app-branding__co-branding"
    >
      <img
        src="../assets/kaspersky-logo_black.png"
        class="app-branding__image app-branding__kaspersky-logo"
        :style="kasperskyLogoStyles"
        alt="kaspersky logo"
        dir="ltr"
      />
      <span class="app-branding__co-branding-x">X</span>
      <div class="app-branding__co-branding-company-logo">
        <img
          :src="branding.company_logo"
          class="app-branding__image app-branding__image_company"
          alt="company logo"
          dir="ltr"
        />
      </div>
    </div>

    <div
      v-else-if="
        branding.type === $options.BRANDING_TYPES.WHITE_LABEL &&
        branding.company_logo
      "
      class="app-branding__white-label"
    >
      <img
        :src="branding.company_logo"
        alt="company logo"
        class="app-branding__image_company"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BRANDING_TYPES } from '../constants/brandingType'

export default {
  BRANDING_TYPES,

  props: {
    kasperskyLogoWidth: { type: Number, default: 150 }
  },

  computed: {
    ...mapGetters({
      branding: 'session/branding'
    }),

    kasperskyLogoStyles() {
      return {
        width: `${this.kasperskyLogoWidth}px`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-branding {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__default {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__image {
    &[dir='ltr'] {
      transform: scaleX(1);
    }

    &_company {
      height: 80px;
    }
  }

  &__co-branding {
    display: flex;
    transform: translateX(-6px);
    align-items: center;
    &-x {
      margin: 0 12px;
    }

    &-company-logo {
      width: 100%;
    }
  }
}
</style>
