import debug from '../../utils/debug'
import GameStates, { GameStatesMap } from '../../constants/gameStates'
import { wasPinResetted } from '../notificationsPinResetLog'
import { getLastElement } from '../../utils/base'
import {
  CURRENT_TEAM_SIMPLE_COLOR,
  CURRENT_TEAM_WINNER_COLOR,
  OTHER_TEAMS_COLOR
} from '../../constants/results'
import {
  compareByField,
  compareByMany,
  invertCompareFunction
} from '../../utils/compare'
import { NAV_PAGES } from '../../constants/ui'

export const GAME_NAMESPACE = '$games'
export const GAME_STATE_CHANGE = 'game.changeState'

const handleGameStateChange = (store, isInfoConsoleGame, params) => {
  let [dataOfSession, dataOfCommand, dataOfStats] = params || []
  let gameState = GameStates[dataOfSession.state]

  //PARSE MAIN SESSION DATA
  store.commit('session/UPDATE_SECTION_DATA', {
    step: dataOfSession.step,
    idHash: dataOfCommand.id
  })
  store.commit('session/SET_GAME_STATE', gameState)

  //ADD PHASE INFO POPUP
  store.dispatch('session/addPhaseInfo', {
    turn: dataOfSession.step,
    phase: gameState
  })

  // SLIDER INITIALIZATION
  if (dataOfSession.slider && dataOfSession.slider.slides) {
    store.commit('session/UPDATE_TRAINER_SLIDES', {
      slides: dataOfSession.slider.slides
    })
    store.dispatch('session/setTrainerWindow', {
      type: 'slides',
      slide: dataOfSession.slider.current_slide,
      show: dataOfSession.slider.visible
    })
  }

  /// NOTIFICATION POINTS
  if (gameState === 'new') {
    store.commit('session/SET_CURRENT_SUB_PAGE', 'main')
    ;['main', 'cards', 'news', 'stats'].forEach(key => {
      this.store.commit('session/SET_NOTIFICATION_POINTS', {
        key,
        value: false
      })
    })
    this.store.dispatch('cards/setCardData', { key: 'cardsInHand', data: [] })
  }
  if (
    gameState === 'phase_news' &&
    !wasPinResetted('news', dataOfSession.step, gameState)
  ) {
    store.commit('session/SET_NOTIFICATION_POINTS', {
      key: 'news',
      value: true
    })
  }
  if (
    gameState === 'phase_reporting' &&
    !wasPinResetted('news', dataOfSession.step, gameState)
  ) {
    store.commit('session/SET_NOTIFICATION_POINTS', {
      key: 'news',
      value: true
    })
  }
  if (
    gameState === 'phase_reporting' &&
    !wasPinResetted('stats', dataOfSession.step, gameState)
  ) {
    store.commit('session/SET_NOTIFICATION_POINTS', {
      key: 'stats',
      value: true
    })
  }
  if (gameState === 'phase_action') {
    store.commit('cards/SET_CARDS_HAND', [])
    store.commit('cards/SET_CHOOSE_STATE', false)
    if (!wasPinResetted('cards', dataOfSession.step, gameState)) {
      store.commit('session/SET_NOTIFICATION_POINTS', {
        key: 'cards',
        value: true
      })
    }
  }

  /// GameBoard
  if (dataOfCommand.infrastructure) {
    store.commit(
      'session/SET_GAMEBOARD_MARKINGS_STATUSES',
      dataOfCommand.infrastructure
    )
  }

  /////// BONUSES /////////////////////////////////////////////////////////////////
  if (gameState === 'after_bonus_slides') {
    const bonusLines = dataOfSession.bonus.messages.map(
      (bonusMessageId, index) => {
        return {
          bonusMessageId,
          revenue: dataOfSession.bonus.revenues[index]
        }
      }
    )

    store.commit('session/UPDATE_BONUS_TABLE', bonusLines)
    store.dispatch('session/setTrainerWindow', {
      type: 'bonus_slides',
      slide: 0,
      show: true
    })
  }
  if (gameState === 'after_bonus_result') {
    const bonusLines = (dataOfCommand?.bonus?.messages || []).map(
      (bonusMessageId, index) => {
        return {
          bonusMessageId,
          revenue: dataOfCommand.bonus.revenues[index]
        }
      }
    )

    store.commit('session/UPDATE_BONUS_TABLE', bonusLines)
    store.dispatch('session/setTrainerWindow', {
      type: 'bonus_results',
      slide: 0,
      show: true
    })
  }

  if (gameState === 'after_statistic') {
    store.commit('session/SET_STATISTIC', dataOfSession.statistic)
  }

  //PARSE MESSAGES LIST (Load to UI news)
  store.dispatch('session/updateSessionActiveMessages', {
    stepActions: dataOfCommand.step_actions,
    cardsList: store.state.cards.cardsList
  })

  //PARSE AVAILABLE CARDS (Load to UI available cards)
  store.dispatch('cards/setCardData', {
    key: 'availableCards',
    data: dataOfCommand.cards
  })
  store.dispatch('cards/setCardData', {
    key: 'usedCards',
    data: dataOfCommand.played_cards
  })
  store.dispatch('cards/setCardData', {
    key: 'newCards',
    data: getLastElement(dataOfCommand.step_actions)?.new_cards || []
  })
  //MANAGE CARDS
  if (gameState === 'phase_reporting' || gameState == 'phase_action_end') {
    store.dispatch('cards/setCardData', {
      key: 'cardsInHand',
      data: []
    })
  }

  //PARSE MONEY AND TIME UNIT OF COMMAND
  store.commit('session/UPDATE_MONEY_COUNT', dataOfCommand.budget)
  if (dataOfCommand.revenue !== null) {
    store.commit('session/UPDATE_MONEY_TOTAL_PROFIT', dataOfCommand.revenue)
  }
  store.commit('session/UPDATE_MONEY_PROFIT', dataOfCommand.step_actions)
  store.commit('session/UPDATE_TIME_COUNT', dataOfSession.step_time_unit)

  /// PARSE OTHER TEAMS STATS ======= [RESULTS] //////////////////////////////////////////////////////////////////
  if (dataOfStats && dataOfStats.length > 0) {
    let isWinner = false
    const teamsTotalSumms = {}
    let diagramData = dataOfStats.map(
      ({ name, num, revenue_history: revenueHistory }) => {
        const steps = [0]
        let summ = 0
        revenueHistory.forEach(historyPoint => {
          if (historyPoint) {
            summ += historyPoint
            steps.push(summ)
          }
        })
        teamsTotalSumms[num] = summ

        return {
          id: num,
          teamName: name,
          steps,
          style: num == dataOfCommand.num ? 'current' : 'others'
        }
      }
    )

    const tableData = dataOfStats
      .map(({ name, num, id, info_console_token }) => {
        const payload = {
          id: num,
          teamName: name,
          totalSum: teamsTotalSumms[num],
          color: OTHER_TEAMS_COLOR,
          currentTeam: num == dataOfCommand.num
        }

        if (isInfoConsoleGame) {
          payload.teamId = id
          payload.teamInfoConsoleToken = info_console_token
        }

        return payload
      })
      .sort(
        compareByMany(
          compareByField('totalSum'),
          (teamA, teamB) => {
            if (teamA.id === dataOfCommand.num) {
              return 1
            } else if (teamB.id === dataOfCommand.num) {
              return -1
            }

            return 0
          },
          invertCompareFunction(compareByField('id'))
        )
      )

    let tempDiagramData = []
    tableData.forEach(item => {
      tempDiagramData.push(diagramData.find(line => line.id === item.id))
    })
    diagramData = tempDiagramData

    tableData.reverse()
    tableData.forEach((item, index, arr) => {
      if (gameState !== 'phase_reporting' && dataOfSession.step === 1) {
        item.place = ''
      } else if (index === 0) {
        item.place = 1
      } else {
        const prevItem = arr[index - 1]
        if (prevItem.totalSum === item.totalSum) {
          item.place = prevItem.place
        } else {
          item.place = prevItem.place + 1
        }
      }

      if (item.currentTeam) {
        isWinner = item.place === 1
        item.color =
          item.place === 1
            ? CURRENT_TEAM_WINNER_COLOR
            : CURRENT_TEAM_SIMPLE_COLOR
      }
    })

    tableData.forEach(item => {
      const length = tableData.length
      const line = diagramData.find(line => line.id === item.id)

      if (item.place === 1) {
        line.style = 'best'
      } else if (item.place === tableData[length - 1].place) {
        line.style = 'worst'
      }
    })
    store.commit('session/UPDATE_RESULTS', {
      diagramData,
      tableData
    })
    store.commit('session/UPDATE_SECTION_DATA', {
      memberCertificate: !isWinner
    })
  }
}

export const attachGameListeners = (emitter, store, isInfoConsoleGame) => {
  emitter
    .on(`publish:${GAME_NAMESPACE}`, () =>
      store.dispatch('session/setTrainerWindow', {
        type: '',
        slide: 0,
        show: false
      })
    )
    .on(`publish:${GAME_NAMESPACE}:game.changeSlide`, ([slide, show]) => {
      const onAfterBonusResultState =
        store.state.session.gameState === GameStatesMap.AfterBonusResult
      const type = onAfterBonusResultState ? 'bonus_slides' : 'slides'

      store.dispatch('session/setTrainerWindow', {
        type,
        slide,
        show: !!show
      })

      if (
        onAfterBonusResultState &&
        !show &&
        store.state.session.currentSubPage !== NAV_PAGES.STATS
      ) {
        store.dispatch('session/setCurrentSubPage', NAV_PAGES.STATS)
      }
    })
    .on(`publish:${GAME_NAMESPACE}:team.doVote`, params => {
      debug.api.team.vote('team.doVote > step', params)
      store.commit('session/UPDATE_VOTES', params)
    })
    .on(`publish:${GAME_NAMESPACE}:team.doMove`, params => {
      debug.api.team.move('team.doMove > step', params)
      store.dispatch('cards/syncWithCardsInHandFromServer', params[1]) /// fixme maybe turn this off for isCaptain
      store.commit('cards/SET_CHOOSE_STATE', params[2])
    })
    .on(`publish:${GAME_NAMESPACE}:team.changeName`, params => {
      debug.api.team.changeName('team.changeName', params[0])
      store.commit('session/UPDATE_SECTION_DATA', {
        teamName: params[0] + ''
      })
    })
    .on(`publish:${GAME_NAMESPACE}:team.changeCaptain`, params => {
      store.dispatch('session/onCaptainChange', ...params)
    })
    .on(`publish:${GAME_NAMESPACE}:team.updatePlayersList`, params => {
      store.commit('session/UPDATE_PLAYER_LIST', Object.values(params))
    })
    .on(`publish:${GAME_NAMESPACE}:${GAME_STATE_CHANGE}`, params => {
      handleGameStateChange(store, isInfoConsoleGame, params)
    })
}
