<template>
  <div class="industrial">
    <div class="industrial__main">
      <img v-if="mainImage" ref="mainImage" :src="mainImage" alt="" dir="ltr" />
      <div
        v-for="(mark, index) in markings"
        :key="index"
        class="industrial__main-mark"
        :class="getMarkModificatorClass(mark)"
        :style="{
          left: `${mark.x * scaleCoordinateX}px`,
          top: `${mark.y * scaleCoordinateY}px`
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import resize from '@/mixins/resize'

const ATTACK_MARKINGS_STATUSES_MAP = {
  0: 'green',
  1: 'yellow',
  2: 'red'
}

export default {
  name: 'IndustrialContent',

  mixins: [resize],

  data: () => ({
    scaleCoordinateX: 0,
    scaleCoordinateY: 0
  }),

  computed: {
    ...mapGetters({
      gameBoard: 'session/gameBoard',
      baseUrl: 'session/getBaseUrl'
    }),

    mainImage() {
      if (!this.gameBoard?.images) {
        return
      }
      const image = this.$store.getters['localizations/localizeContent'](
        this.gameBoard.images
      )

      return image && this.baseUrl + image
    },

    markings() {
      if (
        !this.gameBoard?.infrastructure ||
        this.scaleCoordinateX === 0 ||
        this.scaleCoordinateY === 0
      ) {
        return []
      }

      return this.gameBoard.infrastructure
    }
  },

  watch: {
    mainImage: {
      immediate: true,
      deep: true,
      handler() {
        this.calcScaleCoordinate()
      }
    }
  },

  methods: {
    getMarkModificatorClass(mark) {
      return `industrial__main-mark--${
        ATTACK_MARKINGS_STATUSES_MAP[mark.status]
      }`
    },

    handleResize() {
      this.calcScaleCoordinate()
    },

    calcScaleCoordinate() {
      const { mainImage } = this.$refs
      if (!mainImage || !mainImage.naturalWidth || !mainImage.naturalHeight) {
        this.scaleCoordinateX = 0
        this.scaleCoordinateY = 0
        setTimeout(() => {
          this.calcScaleCoordinate()
        }, 150)
        return
      }

      this.scaleCoordinateX = mainImage.width / mainImage.naturalWidth
      this.scaleCoordinateY = mainImage.height / mainImage.naturalHeight
    }
  },

  mounted() {}
}
</script>

<style lang="scss">
.industrial {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  &__main {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: fit-content;
    height: 100%;

    img {
      width: 100%;
      height: auto;
      max-height: 100%;
      object-fit: contain;
      object-position: top;

      &[dir='ltr'] {
        transform: scaleX(1);
      }
    }

    &-mark {
      height: 16px;
      width: 16px;
      position: absolute;
      background-size: cover;

      &--green {
        margin: 0 auto;
        width: 27px;
        height: 24px;
        transform: translate(-14px, -12px);
        font-size: 18px;
        border-radius: 50%;
        animation: flicker-light-shadow 5s infinite alternate;
        background-image: url(../assets/industrial/bulb_green.svg);

        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border-radius: 50%;
          box-shadow: 5px 3px 44px 20px rgba(96, 219, 29, 0.5);
          background: radial-gradient(
              ellipse at bottom,
              rgba(96, 219, 29, 1) 100%,
              #000 80%
            ),
            radial-gradient(ellipse at 100% 10%, green, lightgreen);
          animation: flicker-light 5s infinite alternate;
        }
      }

      &--yellow {
        margin: 0 auto;
        width: 27px;
        height: 25px;
        transform: translate(-14px, -12px);
        background-image: url(../assets/industrial/bulb_yellow.svg);
        border-radius: 50%;
        box-shadow: rgba(0, 0, 0, 0.1) 0 -1px 7px 1px, inset #cdcd0b 0 -1px 9px,
          #ff0 0 2px 12px;
        -webkit-animation: blinkYellow 1s infinite;
        -moz-animation: blinkYellow 1s infinite;
        -ms-animation: blinkYellow 1s infinite;
        -o-animation: blinkYellow 1s infinite;
        animation: blinkYellow 2s infinite;
      }

      &--red {
        margin: 0 auto;
        width: 24px;
        height: 24px;
        transform: translate(-12px, -12px);
        background-image: url(../assets/industrial/bulb_red.svg);
        border-radius: 50%;
        box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
          rgba(255, 0, 0, 0.5) 0 2px 12px;
        -webkit-animation: blinkRed 0.5s infinite;
        -moz-animation: blinkRed 0.5s infinite;
        -ms-animation: blinkRed 0.5s infinite;
        -o-animation: blinkRed 0.5s infinite;
        animation: blinkRed 0.5s infinite;
      }
    }
  }
}

@-webkit-keyframes blinkRed {
  from {
    background-color: #f00;
  }
  50% {
    background-color: #a00;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
      rgba(255, 0, 0, 0.5) 0 2px 0;
  }
  to {
    background-color: #f00;
  }
}
@-moz-keyframes blinkRed {
  from {
    background-color: #f00;
  }
  50% {
    background-color: #a00;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
      rgba(255, 0, 0, 0.5) 0 2px 0;
  }
  to {
    background-color: #f00;
  }
}
@-ms-keyframes blinkRed {
  from {
    background-color: #f00;
  }
  50% {
    background-color: #a00;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
      rgba(255, 0, 0, 0.5) 0 2px 0;
  }
  to {
    background-color: #f00;
  }
}
@-o-keyframes blinkRed {
  from {
    background-color: #f00;
  }
  50% {
    background-color: #a00;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
      rgba(255, 0, 0, 0.5) 0 2px 0;
  }
  to {
    background-color: #f00;
  }
}
@keyframes blinkRed {
  from {
    background-color: #f00;
  }
  50% {
    background-color: #a00;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
      rgba(255, 0, 0, 0.5) 0 2px 0;
  }
  to {
    background-color: #f00;
  }
}

@-webkit-keyframes blinkYellow {
  from {
    background-color: #ff0;
  }
  50% {
    background-color: #aa0;
    box-shadow: rgba(128, 128, 31, 0.2) 0 -1px 7px 1px, inset #cdcd0b 0 -1px 9px,
      #ff0 0 0 0;
  }
  to {
    background-color: #ff0;
  }
}
@-moz-keyframes blinkYellow {
  from {
    background-color: #ff0;
  }
  50% {
    background-color: #aa0;
    box-shadow: rgba(128, 128, 31, 0.2) 0 -1px 7px 1px, inset #cdcd0b 0 -1px 9px,
      #ff0 0 0 0;
  }
  to {
    background-color: #ff0;
  }
}
@-ms-keyframes blinkYellow {
  from {
    background-color: #ff0;
  }
  50% {
    background-color: #aa0;
    box-shadow: rgba(128, 128, 31, 0.2) 0 -1px 7px 1px, inset #cdcd0b 0 -1px 9px,
      #ff0 0 0 0;
  }
  to {
    background-color: #ff0;
  }
}
@-o-keyframes blinkYellow {
  from {
    background-color: #ff0;
  }
  50% {
    background-color: #aa0;
    box-shadow: rgba(128, 128, 31, 0.2) 0 -1px 7px 1px, inset #cdcd0b 0 -1px 9px,
      #ff0 0 0 0;
  }
  to {
    background-color: #ff0;
  }
}
@keyframes blinkYellow {
  from {
    background-color: #ff0;
  }
  50% {
    background-color: #aa0;
    box-shadow: rgba(128, 128, 31, 0.2) 0 -1px 7px 1px, inset #cdcd0b 0 -1px 9px,
      #ff0 0 0 0;
  }
  to {
    background-color: #ff0;
  }
}

@keyframes flicker-light {
  0% {
    opacity: 6;
  }
  10% {
    opacity: 0.5;
  }
  20% {
    opacity: 0.3;
  }
  23% {
    opacity: 0.4;
  }
  26% {
    opacity: 0.3;
  }
  30% {
    opacity: 0.6;
  }
  40% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.7;
  }
  60% {
    opacity: 0.6;
  }
  70% {
    opacity: 0.5;
  }
  80% {
    opacity: 0.6;
  }
  90% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.7;
  }
}
</style>
